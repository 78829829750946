/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clearNotifications, showError, showSuccess, updateState, toggleToastNotifications } from '../../redux/commonActions';
import Helper from '../../utils/helper';
import { withLocalize } from 'react-localize-redux';
import { Launch20, Restart20 } from '@carbon/icons-react';
import { RESOURCE_API_ERRORS, ResourceAPI } from '../../rest/ResourceAPI';
import { Button, InlineLoading, Link } from 'carbon-components-react';
import InlineNotifications from '../InlineNotifications/InlineNotifications';
import { InstanceInfo } from '../InstanceInfo/InstanceInfo';
import PropTypes from 'prop-types';

const SCOPE = 'clusterUnreachable';

const STATES = {
	IDLE: 'IDLE',
	REFRESHING_CLUSTER: 'REFRESHING_CLUSTER',
	CHECKING_OPTOOLS: 'CHECKING_OPTOOLS'
};

// This page/component displays when a cluster cannot be reached (expired, deleted, down, etc.)
class ClusterUnreachable extends Component {

	constructor (props) {
		super(props);
		this.state = {
			pageState: STATES.IDLE
		};
	}

	componentDidMount () {
		this.props.toggleToastNotifications(false);

		if (this.props.resource_error) {
			let title = 'error_status_not_determined_title';
			let message = 'error_status_not_determined';

			if (this.props.resource_error.error === 'BACKEND_INSTANCE_WAS_DELETED') {
				title = 'error_service_instance_used_title';
				message = 'error_service_instance_used';
			} else if (this.props.resource_error.error === 'BACKEND_OPTOOLS_URL_NOT_PROVIDED') {
				title = 'error_optools_no_url_title';
				message = 'error_optools_no_url';
			} else if (this.props.resource_error.error === 'UNAUTHORIZED') {
				title = 'error_unauthorized_title';
				message = 'error_unauthorized';
			}

			this.props.showError(title, {
				disableCloseButton: true
			}, SCOPE, message);

		}
	}

	componentWillUnmount () {
		this.props.clearNotifications(SCOPE);
	}

	/**
	 * Disables buttons on the page and shows a loading animation while the OpTools deployment for the cluster is refreshed.
	 * @return {Promise<void>} A Promise that resolves whether the refresh succeeds or fails.
	 */
	async refreshCluster () {
		this.props.clearNotifications(SCOPE);

		try {
			console.log(`Refreshing deployment for resource ${this.props.crn_parsed.crn_string}`);
			this.setState({
				pageState: STATES.REFRESHING_CLUSTER,
				endpoint: null
			});
			const response = await ResourceAPI.refreshResource(this.props.crn_parsed.crn_string);
			console.log(`Refresh resource ${this.props.crn_parsed.crn_string}:`, response);

			// Endpoints shouldn't change, but you never know.
			const resource = this.props.resource;
			if (!resource.endpoints) resource.endpoints = {};
			resource.endpoints.api_endpoint = response.endpoint;
			this.props.updateState('settings', {
				resource
			});

		} catch (error) {
			console.error(`Could not refresh resource ${this.props.crn_parsed.crn_string}: ${error}`);
			this.setState({
				pageState: STATES.IDLE
			});
			this.props.showError('error_refresh_title', {}, SCOPE, 'error_refresh');
			return;
		}

		try {
			console.log(`Waiting for OpTools to deploy for resource ${this.props.crn_parsed.crn_string}`);
			this.setState({
				pageState: STATES.CHECKING_OPTOOLS
			});
			const response = await ResourceAPI.waitForOpTools(this.props.crn_parsed.crn_string);
			this.setState({
				pageState: STATES.IDLE,
				endpoint: response.endpoint
			});
			this.props.showSuccess('refresh_success_title', {}, SCOPE, 'refresh_success');

		} catch (error) {
			console.error(`Could not reach OpTools deployment for ${this.props.crn_parsed.crn_string}: ${error}`);
			const errors = {
				status: 'error_optools_unreachable_title',
				message: 'error_optools_unreachable'
			};
			if (error.code === RESOURCE_API_ERRORS.OPTOOLS_TOOK_TOO_LONG) {
				errors.status = 'error_optools_took_too_long_title';
				errors.message = 'error_optools_took_too_long';
			}
			this.setState({
				pageState: STATES.IDLE
			});
			this.props.showError(errors.status, {}, SCOPE, errors.message);
		}
	}

	render () {
		const instance_id = this.props.crn_parsed && this.props.crn_parsed.instance_id;
		const clusterName = this.props.resource && this.props.resource.clusterName;
		const endpoint = this.props.resource && this.props.resource.endpoints && this.props.resource.endpoints.api_endpoint;

		const showRefresh = this.props.resource_error && this.props.resource_error.error === 'BACKEND_OPTOOLS_URL_NOT_PROVIDED';

		const translate = this.props.translate;
		return (
			<div className="hyperion-page-content">
				<h1 className="type-heading-05 mb-s-07">{translate('not_right')}</h1>

				<div className='d-flex'>
					<div className='hyperion-inner-content'>
						<p className="type-body-short-01 mb-s-06">{translate('not_right_desc')}</p>

						<InstanceInfo className="mb-s-06"
							serviceID={instance_id}
							clusterName={clusterName}
						/>

						<InlineNotifications/>

						<div className='mb-s-07'>
							{showRefresh ?
								<Button
									className='hyperion-refresh-button'
									kind='tertiary'
									renderIcon={this.state.pageState !== STATES.IDLE ? InlineLoading : Restart20}
									disabled={this.state.pageState !== STATES.IDLE}
									onClick={() => { this.refreshCluster(); }}
								>
									{translate('refresh_cluster')}
								</Button>
								:
								<Button
									kind='tertiary'
									onClick={() => {
										window.location.href = `/?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
									}}
								>
									{translate('not_right_check_again')}
								</Button>
							}
						</div>

						<div className='mb-s-03'>
							<Link
								className='type-body-short-01'
								href={translate('_SUPPORT', { BMIX_HOST: this.props.bmixHost })}
								target="_blank"
								rel="noopener noreferrer"
							>
								{translate('contact_support')}
							</Link>
						</div>

						<Link
							className='type-body-short-01'
							href={translate('_DOCUMENTATION', { BMIX_HOST: this.props.bmixHost })}
							target="_blank"
							rel="noopener noreferrer"
						>
							{translate('documentation')}
						</Link>
					</div>

					<div className="ml-l-05 d-none d-lg-block">
						<img className="hyperion-banner-image" src='/returning_user_illustration.svg' alt="Welcome illustration"/>
					</div>
				</div>

				<Button
					className='hyperion-button-launch'
					renderIcon={Launch20}
					disabled={!endpoint || this.state.pageState !== STATES.IDLE}
					onClick={() => {
						window.open(endpoint, '_blank');
					}}
				>
					{translate('launchIbp')}
				</Button>
			</div>
		);
	}
}

const dataProps = {
	translate: PropTypes.func // Provided by withLocalize
};

ClusterUnreachable.propTypes = {
	...dataProps
};

export default connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		newprops['bmixHost'] = state['settings'] ? state['settings']['bmixHost'] : null;
		newprops['resource'] = state['settings'] ? state['settings']['resource'] : null;
		newprops['resource_error'] = state['settings'] ? state['settings']['resource_error'] : null;
		return newprops;
	},
	{
		updateState,
		showError,
		showSuccess,
		clearNotifications,
		toggleToastNotifications
	}
)(withLocalize(ClusterUnreachable));
