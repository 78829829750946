/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../utils/helper';
import { updateState } from '../../redux/commonActions';
import { withLocalize } from 'react-localize-redux';
import ButtonBar from '../ButtonBar/ButtonBar';
import { Button, ListItem, OrderedList } from 'carbon-components-react';
import PropTypes from 'prop-types';

const SCOPE = 'aceConfigValues';

class Welcome extends Component {

	render () {
		const translate = this.props.translate;
		return (
			<div className="hyperion-page-content">
				<h1 className="type-heading-05 mb-s-07">{translate('welcomeToIbp')}</h1>

				<p className="type-body-short-01 mb-s-07">{translate('welcomeMessage')}</p>

				<div className="hyperion-welcome-steps-container">
					<OrderedList className="hyperion-welcome-steps">
						<ListItem>
							<h5 className="type-heading-01 hyperion-welcome-step-heading">{translate('welcome_point_1_title')}</h5>
							<p className="type-body-short-01 hyperion-welcome-step-message">{translate('welcome_point_1_message')}</p>
						</ListItem>
						<ListItem>
							<h5 className="type-heading-01 hyperion-welcome-step-heading">{translate('welcome_point_2_title')}</h5>
							<p className="type-body-short-01 hyperion-welcome-step-message">{translate('welcome_point_2_message')}</p>
						</ListItem>
						<ListItem>
							<h5 className="type-heading-01 hyperion-welcome-step-heading">{translate('welcome_point_3_title')}</h5>
							<p className="type-body-short-01 hyperion-welcome-step-message">{translate('welcome_point_3_message')}</p>
						</ListItem>
					</OrderedList>
					<img src='/Welcome to Hyperion Annotated.svg' alt="Welcome illustration" className="welcome-illustration" />
				</div>

				<p className="type-helper-text-01 welcome-secondary-note">{translate('welcome_secondary_note')}</p>

				<ButtonBar>
					<Button
						kind="primary"
						onClick={() => {
							const url = `/create?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
							this.props.history.push(url);
						}}
						disabled={!this.props.crn_parsed.ace_string}
					>
						{translate('lets_setup')}
					</Button>
				</ButtonBar>
			</div>
		);
	}
}

const dataProps = {
	translate: PropTypes.func // Provided by withLocalize
};

Welcome.propTypes = {
	...dataProps
};

export default connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['isBeta'] = state['settings'] ? state['settings']['isBeta'] : null;
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		return newprops;
	},
	{
		updateState
	}
)(withLocalize(Welcome));
