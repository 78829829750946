/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { renderToStaticMarkup } from 'react-dom/server';
import defaultTranslation from '../assets/i18n/en/messages.json';

// load all translation files for localization
const Data = {
	en: require('../assets/i18n/en/messages.json'),
	de: require('../assets/i18n/de/messages.json'),
	es: require('../assets/i18n/es/messages.json'),
	fr: require('../assets/i18n/fr/messages.json'),
	it: require('../assets/i18n/it/messages.json'),
	ja: require('../assets/i18n/ja/messages.json'),
	ko: require('../assets/i18n/ko/messages.json'),
	'pt-br': require('../assets/i18n/pt-br/messages.json'),
	'zh-cn': require('../assets/i18n/zh-cn/messages.json'),
	'zh-tw': require('../assets/i18n/zh-tw/messages.json'),
};
Data['pt'] = Data['pt-br'];
Data['zh'] = Data['zh-cn'];
Data['en-us'] = Data['en'];

const Localization = {

	langs: [
		{ name: 'English', code: 'en' },
		// Enable the following languages when we have translations available
		{ name: 'French', code: 'fr' },
		{ name: 'Spanish', code: 'es' },
		{ name: 'German', code: 'de' },
		{ name: 'Italian', code: 'it' },
		{ name: 'Japanese', code: 'ja' },
		{ name: 'Korean', code: 'ko' },
		{ name: 'Brazilian Portugese', code: 'pt-br' },
		{ name: 'Simplified Chinese', code: 'zh-cn' },
		{ name: 'Traditional Chinese', code: 'zh-tw' }
	],

	loaded: {
		en: defaultTranslation
	},

	getTranslation(lang, callback) {
		if (this.loaded[lang]) {
			callback(this.loaded[lang]);
		} else {
			callback(Data[lang]);
		}
	},

	init(props) {
		props.initialize({
			languages: this.langs,
			translation: defaultTranslation,
			options: {
				renderToStaticMarkup,
				renderInnerHtml: true,
				defaultLanguage: 'en'
			}
		});

		// todo In the future use server-side logic to get the
		// user's preferred language.  This temporary logic will
		// not work for IE
		const preferred = navigator.language;
		if (preferred) {
			let code = preferred.toLowerCase();
			let found = null;
			// check for language and locale match
			this.langs.forEach(lang => {
				if (lang.code === code)
					found = lang.code;

			});
			if (!found && (code.length > 2)) {
				// check for language match
				code = code.substring(0, 2);
				this.langs.forEach(lang => {
					if (lang.code === code)
						found = lang.code;

				});
			}
			if (found)
				this.getTranslation(found, translations => {
					props.addTranslationForLanguage({
						...defaultTranslation,
						...translations
					}, found);
					props.setActiveLanguage(found);
					document.documentElement.setAttribute('lang', found);
				});

		}
	}
};

export default Localization;