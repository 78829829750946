/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
const simulatedNetworkDelay = 0;

class RestApi {

	/**
	 * @typedef {Error} RestAPIError A common error format to expect from RestApi calls.
	 * @property {string} message A description of what went wrong.
	 * @property {string} code An error code.  This code will be a standard code from this library or a code from the
	 * Hyperion API.  This key should be used for error handling logic, such as finding appropriate translation strings
	 * for the error.
	 * @property {object} [json] If the error was caused by a non-2XX response, the response body will be included here,
	 * if it's available.
	 */

	/**
	 * Send an HTTP request to the given url.
	 * @param {string} url The url (ex. '/api/checkoptools')
	 * @param {string} method The HTTP request method ('GET', 'POST', 'PUT', etc.)
	 * @param {object} [body] The JSON body of the request.
	 * @param {object} [query] The query parameters for the request as an object of key-value pairs.
	 * @returns {Promise<object>} A promise that resolves with the response from the server, if the request was successful.
	 *   If the request could not be sent, or the response is not in the 2XX range, the Promise rejects with
	 */
	static async httpSend (url, method, body, query) {
		const opts = {
			method,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json'
			}
		};
		if (body) opts.body = JSON.stringify(body);

		if (query) {
			if (typeof query !== 'object') {
				const error = new TypeError('Invalid query parameters');
				error.code = REST_API_ERRORS.INVALID_QUERY_PARAMETERS;
			}
			const params = [];
			Object.keys(query).forEach(key => params.push(`${key}=${encodeURIComponent(query[key])}`));
			url = `${url}?${params.join('&')}`;
			console.log(`URL with query parameters: ${url}`);
		}

		let res;
		try {
			console.log(`Sending ${method.toUpperCase()} request to ${url}`);
			res = await fetch(url, opts);
		} catch (error) {
			console.error(`Request to backend failed: ${error}`);
			error.code = REST_API_ERRORS.REQUEST_FAILED;
			throw error;
		}

		let json;
		try {
			const contentType = res.headers.get('content-type');
			if (contentType && contentType.indexOf('application/json') >= 0)
				json = await res.json();
			else
				json = { message: await res.text() };
		} catch (error) {
			console.error(`Response from the server could not be parsed: ${error}`);
			error.code = REST_API_ERRORS.INVALID_RESPONSE_FORMAT;
			throw error;
		}

		// A 207 indicates that the request was sustained using keep-alive middleware on the server.  We have to parse
		// the _actual_ response status and body out of the response body
		let keepAliveWithError;
		if (res.status === 207) {
			console.warn('Keep alives were needed to keep this request going');
			if (json.status && (json.status < 200 || json.status > 299))
				keepAliveWithError = true;
			// only json or text are supported right now.  Just try to mirror the formats above.
			if (json && json.content_type === 'application/json')
				json = json.body;
			else
				json = { message: json.body };
		}

		// Attach the response from the server to the response property, similar to the way axios does on the server-side code
		if (!res.ok || keepAliveWithError) {
			const error = new Error(json.message ? json.message : res.statusText);
			error.code = error.code ? error.code : REST_API_ERRORS.REQUEST_ERROR_IN_RESPONSE;
			if (json) error.response = json;
			console.error(`Request to ${url} error: ${error}:`, json);
			throw error;
		}

		console.log(`${method} ${url} response:`, json);
		await delay(simulatedNetworkDelay);
		return json;
	}

	static async get (url, query) {
		return await RestApi.httpSend(url, 'GET', null, query);
	}

	static async post (url, body, query) {
		return await RestApi.httpSend(url, 'POST', body, query);
	}

	static async put (url, body, query) {
		return await RestApi.httpSend(url, 'PUT', body, query);
	}

	static async delete (url, body, query) {
		return await RestApi.httpSend(url, 'DELETE', body, query);
	}
}

/**
 * Wait for the given number of milliseconds.
 * @param {number} ms Delay length.
 * @returns {Promise<void>} A Promise that resolves with the delay timer is finished.
 */
async function delay (ms) {
	return await new Promise((resolve, reject) => {
		setTimeout(resolve, ms);
	});
}

const REST_API_ERRORS = {
	INVALID_QUERY_PARAMETERS: 'INVALID_QUERY_PARAMETERS',
	REQUEST_FAILED: 'REQUEST_FAILED',
	REQUEST_ERROR_IN_RESPONSE: 'REQUEST_ERROR_IN_RESPONSE',
	INVALID_RESPONSE_FORMAT: 'INVALID_RESPONSE_FORMAT'
};

export {
	RestApi,
	REST_API_ERRORS
};
