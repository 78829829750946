/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// Contains the buttons along the bottom of the pages
class ButtonBar extends Component {
	render () {
		return (
			<div className="hyperion-button-bar-container">
				<div className="hyperion-button-bar-inner">
					{this.props.children}
				</div>
			</div>
		);
	}
}

ButtonBar.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
};

export default ButtonBar;
