import React from 'react';
import { SkeletonPlaceholder } from 'carbon-components-react';

const SkeletonPage = () => (
	<div className="hyperion-skeletong-page-container">
		<div className="hyperion-skeleton-timeline-container">
			<SkeletonPlaceholder className="hyperion-timeline-skeleton"/>
			<SkeletonPlaceholder className="hyperion-timeline-skeleton"/>
			<SkeletonPlaceholder className="hyperion-timeline-skeleton"/>
			<SkeletonPlaceholder className="hyperion-timeline-skeleton"/>
		</div>
		<div className="hyperion-skeleton-main-content">
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
			<SkeletonPlaceholder className="hyperion-main-content-skeleton"/>
		</div>
	</div>
);

export default SkeletonPage;