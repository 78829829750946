/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { SHOW_NOTIFICATION, UPDATE_STATE, CLEAR_NOTIFICATIONS, TOGGLE_TOAST_NOTIFICATIONS } from '../actions';

export default function rootReducer (state = {}, action) {
	let newState;
	switch (action.type) {
		case UPDATE_STATE:
			newState = {
				...state
			};
			if (action.payload && action.payload.scope)
				newState[action.payload.scope] = {
					...newState[action.payload.scope],
					...action.payload.data
				};

			return newState;
		case SHOW_NOTIFICATION:
			newState = {
				...state
			};
			if (action.payload.type && action.payload.message) {
				let newList = [];
				if (!newState.notifications)
					newState.notifications = {};

				if (newState.notifications.list)
					newList = [...newState.notifications.list];

				newList.push({
					id: new Date().getTime(),
					...action.payload
				});
				newState.notifications.list = newList;
			}
			return newState;
		case CLEAR_NOTIFICATIONS:
			newState = {
				...state
			};
			if (!newState.notifications)
				newState.notifications = {};

			if (action.payload) {
				const list = [];
				if (newState.notifications.list)
					newState.notifications.list.forEach(notify => {
						if (notify.scope !== action.payload)
							list.push(notify);

					});

				newState.notifications.list = list;
			} else
				newState.notifications.list = [];

			return newState;
		case TOGGLE_TOAST_NOTIFICATIONS:
			newState = {
				...state
			};
			if (!newState.notifications)
				newState.notifications = {};
			newState.notifications.disable_toast_notifications = !!(action.payload && action.payload.disable_toast_notifications);

			return newState;
		default:
			return state;
	}
}
