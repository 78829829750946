/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import {
	Button, CodeSnippet, Dropdown,



	DropdownSkeleton, InlineLoading,
	ToastNotification
} from 'carbon-components-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { clearNotifications, showError, updateState } from '../../redux/commonActions';
import { DEFAULT_OPTOOLS_TIMEOUT, ResourceAPI, RESOURCE_API_ERRORS } from '../../rest/ResourceAPI';
import { REST_API_ERRORS } from '../../rest/RestApi';
import Clipboard from '../../utils/clipboard';
import Helper from '../../utils/helper';
import ButtonBar from '../ButtonBar/ButtonBar';
import TranslateLink from '../TranslateLink/TranslateLink';

const SCOPE = 'aceConfigValues';
const SETTINGS_SCOPE = 'settings';

const STATES = {
	IDLE: 'IDLE',
	GETTING_CLUSTERS: 'GETTING_CLUSTERS',
	LINKING_CLUSTER: 'LINKING_CLUSTER',
	CHECKING_OPTOOLS: 'CHECKING_OPTOOLS'
};

class LinkToCluster extends Component {

	constructor(props) {
		super(props);

		this.state = {
			clusters: [],
			pageState: STATES.IDLE,
			selected_cluster: null
		};
	}

	componentDidMount() {
		console.log('-----CRN INFO-----', this.props.crn_parsed);
		this.checkForUpdates();
	}

	componentWillUnmount() {
		this.props.clearNotifications(SCOPE);
	}

	/**
	 * Gets a list of clusters that the service instance can be linked to.
	 * @returns {void}
	 */
	checkForUpdates() {
		console.log(`-----Getting the list of clusters for crn ${this.props.crn_parsed.crn_string}-----`);
		this.setState({
			pageState: STATES.GETTING_CLUSTERS,
			selected_cluster: null,
			clusters: []
		});

		// Populate list/dropdown
		ResourceAPI.listResourceClusters(this.props.crn_parsed.crn_string).then(clusters => {
			console.log('LIST OF CLUSTERS:', clusters);
			this.setState({
				clusters: clusters && clusters.clusters ? clusters.clusters : [],
				pageState: STATES.IDLE
			});

			// TODO handle these clusters in the deployer APIs lib
			if (clusters.errors && clusters.errors.length > 0)
				this.props.showError('error_cluster_errors_title', {}, SCOPE, 'error_unauthorized');

		}).catch(error => {

			console.error(`Failed to lookup clusters: ${JSON.stringify(error)}`);
			const errors = {
				status: 'error_list_clusters_title',
				message: 'error_list_clusters'
			};

			if (error.response && error.code !== REST_API_ERRORS.INVALID_RESPONSE_FORMAT)
				if (error.response.error === 'UNAUTHORIZED') {
					errors.status = 'error_unauthorized_title';
					errors.message = 'error_unauthorized';
				}

			this.setState({
				pageState: STATES.IDLE
			});
			this.props.showError(errors.status, {}, SCOPE, errors.message);
		});
	}

	/**
	 * Links the selected cluster with the current service instance, displaying notifications if the process fails and
	 * changing pages if it succeeds.
	 * @param {function} translate A translate function to support localization.
	 * @returns {void}
	 */
	async linkCluster(translate) {
		this.setState({
			pageState: STATES.LINKING_CLUSTER
		});

		console.log(`Linking cluster ${this.state.selected_cluster.id} with resource '${this.props.crn_parsed.crn_string}'`);
		let response;
		try {
			response = await ResourceAPI.createResource(this.props.crn_parsed.crn_string, this.state.selected_cluster.id);
			console.log(`Linked cluster ${this.state.selected_cluster.id} with resource '${this.props.crn_parsed.crn_string}'`);


			response = await ResourceAPI.getResource(this.props.crn_parsed.crn_string);
			console.log('Created resource record:', response.resource);

			// Update the resource record so the success page has the endpoint urls and cluster info
			this.props.updateState('settings', {
				resource: response.resource
			});

		} catch (error) {
			console.error(`Could not link cluster ${this.state.selected_cluster.id}: ${error}`);

			const errors = {
				status: 'error_link_cluster_title',
				message: 'error_link_cluster'
			};

			if (error.response && error.code !== REST_API_ERRORS.INVALID_RESPONSE_FORMAT) {
				// We were asked to show an additional reminder for multi zone clusters
				const isMultiZone = this.state.selected_cluster && this.state.selected_cluster.workerZones
					&& this.state.selected_cluster.workerZones.length > 1;

				if (error.response.error === 'BACKEND_REQUEST_TIMED_OUT') {
					errors.status = 'error_link_timeout_title';
					errors.message = <TranslateLink
						className="type-body-short-01"
						text="error_link_timeout"
						params={{ BMIX_HOST: this.props.bmixHost }}
					/>;
				} else if (error.response.error === 'BACKEND_ERROR_IN_RESPONSE' && isMultiZone)
					errors.message = 'error_link_multizone';
			}

			this.setState({
				pageState: STATES.IDLE
			});
			this.props.showError(errors.status, {}, SCOPE, errors.message);
			return;
		}

		this.setState({
			pageState: STATES.CHECKING_OPTOOLS
		});

		console.log(`Checking the status of console for resource '${this.props.crn_parsed.crn_string}'`);
		try {
			response = await ResourceAPI.waitForMultipleResponses(this.props.crn_parsed.crn_string,
				DEFAULT_OPTOOLS_TIMEOUT, this.props.CONSECUTIVE_CONSOLE_RESPONSES);
			this.props.updateState('settings', {
				console_settings: response.settings
			});
		} catch (error) {
			if (error && error.code === RESOURCE_API_ERRORS.OPTOOLS_HARD_TO_REACH)
				this.props.updateState('settings', {
					console_settings: error.last_successful_response && error.last_successful_response.settings,
					optools_error: error
				});
			else {
				console.log(`Could not get the status of console: ${error}`);
				this.setState({
					pageState: STATES.IDLE
				});
				this.props.updateState('settings', {
					console_settings: null
				});
				this.props.showError('optoolsUnreachableTitle', {}, SCOPE, 'optoolsUnreachable');
				return;
			}
		}

		// get iam token for user
		try {
			console.log('[migrate] 2 trying iam token api');
			const iamResp = await ResourceAPI.getIamToken(this.props.crn_parsed.crn_string);
			console.log('[migrate] 2 got iam token response');
			this.props.updateState(SETTINGS_SCOPE, {
				accessToken: iamResp ? iamResp.accessToken : '',
				refreshToken: iamResp ? iamResp.refreshToken : '',
			});
		} catch (error) {
			console.error('[migrate] 2 unable to get iam token response', error);
		}

		this.setState({
			pageSTATE: STATES.IDLE
		});
		console.log(`Console endpoint: ${response.endpoint}`);
		this.props.history.push(`/success?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`);
	}

	/**
	 * Determines whether we should let a user attempt to link this service instance to the given cluster.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @return {boolean} Returns true if there are no problems with the cluster, false otherwise.
	 */
	static isSelectable(cluster) {
		if (LinkToCluster.isExpired(cluster)) return false;

		for (const i in cluster.notifications)
			if (cluster.notifications[i].level === 'error') return false;

		return true;
	}

	/**
	 * Determines whether the given cluster has expired or not.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @return {boolean} True if the cluster is expired, false otherwise.
	 */
	static isExpired(cluster) {

		// Paid clusters do not expired
		if (cluster.isPaid) return false;

		// Don't let users link a cluster with less than an hour of life
		return LinkToCluster.timeLeftMs(cluster) < 60 * 60 * 1000;
	}

	/**
	 * Calculates the amount of time left on a free cluster's 30 day lifespan.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @returns {number} The amount of time left before the cluster expires, in ms.
	 */
	static timeLeftMs(cluster) {
		// Free clusters expire after 30 days
		const created = new Date(cluster.createdDate);

		const expires = new Date(created);
		expires.setDate(created.getDate() + 30);

		return Math.max(expires - Date.now(), 0);
	}

	/**
	 * Renders a list of cluster notifications from Deployer.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {null|ToastNotification[]} Returns a list of ToastNotifications or null, if no cluster notifications were given.
	 */
	static renderNotifications(cluster, translate) {
		const items = cluster.notifications.map((item) => {
			return LinkToCluster.renderNotification(item, cluster, translate);
		});
		return items.length > 0 ? items : null;
	}

	/**
	 * Renders the JSX for a single cluster notification object. If the notification is of an unknown type, as happens
	 * when updates are made to Deployer, a generic warning notification will be shown.
	 * @param {object} notification A cluster notification object from Deployer.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {ToastNotification} A ToastNotification describing the cluster notification to the user.
	 */
	static renderNotification(notification, cluster, translate) {
		if (!notification) return console.error('Invalid notification could not be rendered');

		console.log(`Notification level: ${notification.level}`);
		let title, kind;
		if (notification.level === 'error') {
			title = translate('error');
			kind = 'error';
		} else {
			title = translate('warning');
			kind = 'warning';
		}

		console.log(`Notification type: ${notification.reason}`);
		let message, caption;
		if (notification.reason === 'state') {
			const translate_key = notification.level === 'error' ? 'cluster_notification_state_error' : 'cluster_notification_state_warning';
			message = translate(translate_key, {
				STATE: notification.currState
			});
		} else if (notification.reason === 'unsupported_cluster_type') {
			message = translate('cluster_notification_type', {
				TYPE: notification.currType ? notification.currType : 'unknown'
			});

			let snippet_text = '';
			if (notification.acceptableTypes)
				snippet_text = JSON.stringify(notification.acceptableTypes, 0, 3);

			caption = <CodeSnippet
				type="multi"
				onClick={() => { Clipboard.copyToClipboard(snippet_text); }}
			>
				{snippet_text}
			</CodeSnippet>;
		} else if (notification.reason === 'version')
			message = translate('cluster_notification_version', {
				REQUIRED: notification.minVersion ? notification.minVersion : '',
				CURRENT: notification.currVersion ? notification.currVersion : '',
				MAXIMUM: notification.maxVersion ? notification.maxVersion : ''
			});
		else if (notification.reason === 'in_use') {
			if (notification.instanceIDs && notification.instanceIDs.length) {
				const snippet_text = JSON.stringify(notification.instanceIDs, 0, 3);
				caption = <div>
					{translate('cluster_notification_in_use_instances')}
					<CodeSnippet
						type="multi"
						onClick={() => { Clipboard.copyToClipboard(snippet_text); }}
					>
						{snippet_text}
					</CodeSnippet>
				</div>;
			}

			let translate_key = 'cluster_notification_in_use_error';
			if (notification.level === 'warning')
				if (notification.instanceIDs && notification.instanceIDs.length > 1) {
					translate_key = 'cluster_notification_in_use_warning';
					if (cluster.isPaid)
						translate_key = 'cluster_notification_in_use_warning_paid';
				} else {
					translate_key = 'cluster_notification_in_use_warning_singular';
					if (cluster.isPaid)
						translate_key = 'cluster_notification_in_use_warning_paid_singular';
				}

			message = translate(translate_key, {
				INSTANCES: notification.instanceIDs && notification.instanceIDs.length ? notification.instanceIDs.length : '1+'
			});
		} else if (notification.level === 'error') {
			message = translate('cluster_notification_unknown_error');
			const snippet_text = JSON.stringify(notification, 0, 3);
			caption = <CodeSnippet
				type="multi"
				onClick={() => { Clipboard.copyToClipboard(snippet_text); }}
			>
				{snippet_text}
			</CodeSnippet>;
		} else {
			message = translate('cluster_notification_unknown_warning');
			const snippet_text = JSON.stringify(notification, 0, 3);
			caption = <CodeSnippet
				type="multi"
				onClick={() => { Clipboard.copyToClipboard(snippet_text); }}
			>
				{snippet_text}
			</CodeSnippet>;
		}

		return <ToastNotification
			className={'hyperion-cluster-notification'}
			hideCloseButton={true}
			kind={kind}
			lowContrast
			title={`${title}:`}
			subtitle={message}
			caption={caption ? caption : false}
		/>;
	}

	/**
	 * Renders a notification to let the user know whether there cluster is free and, if it is, whether or not it has
	 * expired.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {ToastNotification|null} Returns a ToastNotification if the cluster is free, null otherwise.
	 */
	static renderFreeClusterNotification(cluster, translate) {
		console.log('Rendering cluster notification');
		if (cluster.isPaid) return null;

		console.log('Cluster is a free cluster');
		let time_left = null;
		if (LinkToCluster.isExpired(cluster))
			time_left = translate('freeClusterExpired');
		else {
			const diff = LinkToCluster.timeLeftMs(cluster);
			const days = Math.floor(diff / (24 * 60 * 60 * 1000));
			const hours = Math.floor(diff / (60 * 60 * 1000));
			if (days < 2)
				time_left = translate('freeClusterTimeLeftHours', { TIME: hours });
			else
				time_left = translate('freeClusterTimeLeftDays', { TIME: days });
		}

		return <ToastNotification
			className={'hyperion-cluster-notification'}
			hideCloseButton={true}
			caption={time_left}
			kind={'warning'}
			lowContrast
			title={translate('usingFreeCluster')}
			subtitle={translate('usingFreeClusterDescription')}
		/>;
	}

	/**
	 * Builds a notification for clusters that are multizone capable that lets users know that they need VLAN spanning
	 * enabled in order to use the cluster.
	 * @param {string} bmixHost A link to an IBM Cloud environment (cloud.ibm.com, test.cloud.ibm.com, etc.)
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {ToastNotification|null} A notification if the cluster spans multiple zones, null otherwise.
	 */
	static renderMultizoneClusterNotification(bmixHost, cluster, translate) {
		if (!cluster || !cluster.isPaid)
			return null;

		return <>
			<ToastNotification
				kind={'warning'}
				className={'hyperion-cluster-notification'}
				caption={false}
				title={translate('usingMultizoneCluster')}
				lowContrast
				subtitle={<TranslateLink
					className="type-body-short-01"
					text="usingMultizoneClusterDescription"
					params={{ BMIX_HOST: bmixHost }}
				/>}
				hideCloseButton={true}
			/>
			<ToastNotification
				kind={'warning'}
				className={'hyperion-cluster-notification'}
				caption={false}
				title={translate('multizoneTwoWorkersTitle')}
				lowContrast
				subtitle={<TranslateLink
					className="type-body-short-01"
					text="multizoneTwoWorkers"
					params={{ BMIX_HOST: bmixHost }}
				/>}
				hideCloseButton={true}
			/>
		</>;
	}

	/**
	 * Transforms the cluster object into a user-friendly string that we can use to describe the cluster in a list.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {string} A string describing the cluster.
	 */
	static clusterToString(cluster, translate) {

		if (!cluster) return 'invalid cluster';

		const cluster_desc_opts = {
			cluster_name: cluster.name,
			cluster_fields: ''
		};

		if (!cluster.isPaid)
			cluster_desc_opts.cluster_fields = translate('cluster_fields_free');

		const cluster_description = translate('cluster_description', cluster_desc_opts);

		// Wrap the cluster info with a * or ! or something to indicate this cluster isn't usable
		if (!LinkToCluster.isSelectable(cluster))
			return translate('cluster_in_trouble', {
				cluster_description
			});

		let cluster_date = '';
		if (cluster && typeof cluster.createdDate === 'string') {
			cluster_date = ' - ' + cluster.createdDate.substring(0, 10);
		}

		return cluster_description + cluster_date;
	}

	/**
	 * Transforms the cluster object into a user-friendly string that we can use to describe the cluster in a list.
	 * @param {object} cluster A cluster object from the Deployer APIs.
	 * @param {function} translate A translate function to support localization.
	 * @return {object} A description list describing the cluster.
	 */
	static clusterInfo(cluster, translate) {
		if (!cluster) return 'invalid cluster';

		const cluster_info = [
			cluster.type
		];

		let machine_type = translate('cluster_machine_type_free');
		if (cluster.isPaid)
			machine_type = translate('cluster_machine_type_paid');
		cluster_info.push(translate('cluster_machine_type', {
			machine_type
		}));

		cluster_info.push(translate('cluster_version', {
			k8s_version: cluster.masterKubeVersion
		}));

		if (cluster.isPaid)

			cluster_info.push(translate('cluster_workers', {
				num_workers: cluster.workerCount
			}));

		// cluster_info.push(translate('cluster_zones', {
		// 	num_zones: cluster.workerZones && cluster.workerZones.length
		// }));

		if (typeof cluster.createdDate === 'string') {
			cluster_info.push(cluster.createdDate.substring(0, 10));
		}
		if (typeof cluster.id === 'string') {
			cluster_info.push(cluster.id);
		}


		const info = cluster_info.join(translate('cluster_info_separator'));

		return <p className="type-helper-text-01 mt-s-03">
			{translate('cluster_selected_prefix', { cluster_info: info })}
		</p>;
	}

	render() {
		const invalid = this.state.pageState === STATES.IDLE && (!this.state.clusters || !this.state.clusters.length);

		const translate = this.props.translate;
		return (
			<div className="hyperion-page-content">
				<h1 className="type-heading-05 mb-s-07">{translate('deployIbpHeader')}</h1>

				<div className="hyperion-create-cluster-dropdown-container">
					{this.state.pageState === STATES.GETTING_CLUSTERS ?
						<div className="skeleton-wrapper">
							<DropdownSkeleton />
						</div>
						:
						<Dropdown
							id="hyperion-cluster-list-dropdown"
							disabled={this.props.betaOver || this.state.pageState !== STATES.IDLE || !this.state.clusters
								|| this.state.clusters.length < 1}
							label={translate('selectIksDropdownLabel')}
							items={this.state.clusters || []}
							onChange={(event) => {
								this.setState({
									selected_cluster: event.selectedItem
								});
							}}
							invalid={invalid}
							invalidText={translate('link_cluster_invalid')}
							itemToString={cluster => { return LinkToCluster.clusterToString(cluster, translate); }}
						/>
					}
					<Button
						kind="ghost"
						onClick={() => { this.checkForUpdates(); }}
						disabled={this.state.pageState !== STATES.IDLE}
					>
						{translate('link_cluster_check_again')}
					</Button>
				</div>
				{this.state.selected_cluster && LinkToCluster.clusterInfo(this.state.selected_cluster, translate)}

				<TranslateLink
					className="type-body-short-01 mt-s-07 mb-s-06"
					text='iksChargeWarning'
					params={{ BMIX_HOST: this.props.bmixHost }}
				/>

				{this.state.selected_cluster && this.state.selected_cluster.notifications &&
					LinkToCluster.renderNotifications(this.state.selected_cluster, translate)}
				{this.state.selected_cluster && !this.state.selected_cluster.isPaid &&
					LinkToCluster.renderFreeClusterNotification(this.state.selected_cluster, translate)}
				{this.state.selected_cluster &&
					LinkToCluster.renderMultizoneClusterNotification(this.props.bmixHost, this.state.selected_cluster, translate)}

				<ButtonBar>
					<Button
						kind="secondary"
						onClick={() => {
							const url = `/create?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
							this.props.history.push(url);
						}}
						disabled={this.state.pageState !== STATES.IDLE}
					>
						{translate('back')}
					</Button>
					<Button
						kind="primary"
						disabled={
							this.props.betaOver ||
							!this.state.selected_cluster ||
							!LinkToCluster.isSelectable(this.state.selected_cluster) ||
							this.state.pageState !== STATES.IDLE
						}
						onClick={() => this.linkCluster(translate)}
						renderIcon={[
							STATES.LINKING_CLUSTER,
							STATES.CHECKING_OPTOOLS
						].indexOf(this.state.pageState) >= 0 ? InlineLoading : null}
					>
						{translate('continue')}
					</Button>
				</ButtonBar>
			</div>
		);
	}
}

const dataProps = {
	translate: PropTypes.func // Provided by withLocalize
};

LinkToCluster.propTypes = {
	...dataProps
};

export default connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['betaOver'] = state['settings'] ? state['settings']['betaOver'] : null;
		newprops['isBeta'] = state['settings'] ? state['settings']['isBeta'] : null;
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		newprops['bmixHost'] = state['settings'] ? state['settings']['bmixHost'] : null;
		newprops['CONSECUTIVE_CONSOLE_RESPONSES'] = state['settings'] ? state['settings']['CONSECUTIVE_CONSOLE_RESPONSES'] : null;
		return newprops;
	},
	{
		updateState,
		showError,
		clearNotifications
	}
)(withLocalize(LinkToCluster));
