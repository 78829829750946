/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateState, showError, showSuccess, showWarning, clearNotifications, toggleToastNotifications } from '../../redux/commonActions';
import Helper from '../../utils/helper';
import { withLocalize } from 'react-localize-redux';
import { Button, InlineLoading, Link, Tag, TagSkeleton } from 'carbon-components-react';
import { Restart20, Launch20 } from '@carbon/icons-react';
import {
	DEFAULT_REFRESH_TIMEOUT,
	DEFAULT_UPTIME_THRESHOLD,
	RESOURCE_API_ERRORS,
	ResourceAPI
} from '../../rest/ResourceAPI';
import InlineNotifications from '../InlineNotifications/InlineNotifications';
import { InstanceInfo } from '../InstanceInfo/InstanceInfo';
import { REST_API_ERRORS } from '../../rest/RestApi';
import TranslateLink from '../TranslateLink/TranslateLink';
import PropTypes from 'prop-types';

const SCOPE = 'welcomeBack';

const STATES = {
	IDLE: 'IDLE',
	REFRESHING_CLUSTER: 'REFRESHING_CLUSTER',
	CHECKING_OPTOOLS: 'CHECKING_OPTOOLS'
};

class WelcomeBack extends Component {

	constructor(props) {
		super(props);
		this.state = {
			pageState: STATES.IDLE
		};
	}

	componentDidMount() {
		this.props.toggleToastNotifications(false);

		if (this.props.optools_error) {
			const code = this.props.optools_error.code;
			if (code === RESOURCE_API_ERRORS.OPTOOLS_HARD_TO_REACH) {
				this.props.showWarning('error_console_hard_to_reach_title', {
					disableCloseButton: true
				}, SCOPE, 'error_console_hard_to_reach');
				return;
			}

			const errors = {
				status: 'error_optools_unreachable_title',
				message: 'error_optools_unreachable'
			};
			if (this.props.optools_error.code === RESOURCE_API_ERRORS.OPTOOLS_TOOK_TOO_LONG) {
				errors.status = 'error_optools_took_too_long_title';
				errors.message = 'error_optools_took_too_long';
			}
			this.props.showError(errors.status, {
				disableCloseButton: true
			}, SCOPE, errors.message);
		}
	}

	/**
	 * Disables buttons on the page and shows a loading animation while the OpTools deployment for the cluster is refreshed.
	 * @return {Promise<void>} A Promise that resolves whether the refresh succeeds or fails.
	 */
	async refreshCluster() {
		this.props.clearNotifications(SCOPE);

		// first check the optools settings to see if it has started migration
		let console_settings_resp = null;
		try {
			const tmp = await ResourceAPI.checkResourceOpToolsDeployment(this.props.crn_parsed.crn_string);
			console.log('[migrate check] console settings response full:', tmp);
			console_settings_resp = tmp ? tmp.settings : null;
		} catch (e) {
			console.error('[migrate check] unable to get console settings. e:', e);
		}

		// if we have started migration -> do not send the refresh cluster api
		if (console_settings_resp && console_settings_resp.MIGRATION_STATUS) {
			console.log('[migrate check] data:', console_settings_resp.MIGRATION_STATUS);
			if (console_settings_resp.MIGRATION_STATUS.ingress_start_ts) {
				console.log('[migrate check] ingress has been migrated');
				this.props.showError('ingress_migrated_title', {}, SCOPE, 'ingress_migrated');

				// update settings so the refresh button gets hidden
				this.props.updateState('settings', {
					console_settings: console_settings_resp
				});

				// do not continue, stop the refresh api from going
				return;
			}
		}
		console.log('[migrate check] ingress has NOT been migrated. all clear.');

		try {
			console.log(`Refreshing deployment for resource ${this.props.crn_parsed.crn_string}`);
			this.setState({
				pageState: STATES.REFRESHING_CLUSTER,
				endpoint: null
			});
			const response = await ResourceAPI.refreshResource(this.props.crn_parsed.crn_string);
			console.log(`Refresh resource ${this.props.crn_parsed.crn_string}:`, response);

			// Endpoints shouldn't change, but you never know.
			const resource = this.props.resource;
			if (!resource.endpoints) resource.endpoints = {};
			resource.endpoints.api_endpoint = response.endpoint;
			this.props.updateState('settings', {
				resource
			});

		} catch (error) {
			console.error(`Could not refresh resource ${this.props.crn_parsed.crn_string}: ${error}`);
			this.setState({
				pageState: STATES.IDLE
			});

			const notification = {
				status: 'error_refresh_title',
				message: 'error_refresh'
			};

			if (error.response && error.code !== REST_API_ERRORS.INVALID_RESPONSE_FORMAT)
				if (error.response.error === 'BACKEND_REQUEST_TIMED_OUT') {
					notification.status = 'error_refresh_timeout_title';
					// without p-relative, you can't click on the link!
					notification.message = <TranslateLink
						className="type-body-short-01 p-relative"
						text="error_refresh_timeout"
						params={{ BMIX_HOST: this.props.bmixHost }}
					/>;
				}

			this.props.showError(notification.status, {}, SCOPE, notification.message);
			return;
		}

		try {
			console.log(`Waiting for OpTools to deploy for resource ${this.props.crn_parsed.crn_string}`);
			this.setState({
				pageState: STATES.CHECKING_OPTOOLS
			});
			const response = await ResourceAPI.waitForRefreshedOpTools(this.props.crn_parsed.crn_string,
				DEFAULT_REFRESH_TIMEOUT,
				this.props.REFRESH_CONSOLE_UPTIME_THRESHOLD || DEFAULT_UPTIME_THRESHOLD);
			this.setState({
				pageState: STATES.IDLE,
				endpoint: response.endpoint
			});
			this.props.updateState('settings', {
				console_settings: response.settings
			});
			this.props.showSuccess('refresh_success_title', {}, SCOPE, 'refresh_success');

		} catch (error) {
			console.error(`Could not reach OpTools deployment for ${this.props.crn_parsed.crn_string}: ${error}`);
			const errors = {
				status: 'error_optools_unreachable_title',
				message: 'error_optools_unreachable'
			};
			if (error.code === RESOURCE_API_ERRORS.OPTOOLS_TOOK_TOO_LONG) {
				errors.status = 'error_optools_took_too_long_title';
				errors.message = 'error_optools_took_too_long';
			} else if (error.code === RESOURCE_API_ERRORS.OPTOOLS_MAY_NOT_BE_REFRESHING) {
				errors.status = 'console_not_refreshing_title';
				errors.message = 'console_not_refreshing_title';
			} else if (error.code === RESOURCE_API_ERRORS.OPTOOLS_INVALID_UPTIME) {
				errors.status = 'console_not_refreshing_title';
				errors.message = 'console_not_refreshing_title';
			}

			// The user may still be able to use their console, so show the version we got and enable the console button
			if (error.response) {
				this.props.updateState('settings', {
					console_settings: error.response.settings
				});
				this.setState({
					endpoint: error.response.endpoint
				});
			}

			this.setState({
				pageState: STATES.IDLE
			});
			this.props.showError(errors.status, {}, SCOPE, errors.message);
		}
	}

	render() {
		const instance_id = this.props.crn_parsed && this.props.crn_parsed.instance_id;
		const clusterName = this.props.resource && this.props.resource.clusterName;
		const endpoint = this.props.resource && this.props.resource.endpoints && this.props.resource.endpoints.api_endpoint;
		const title = this.props.title ? this.props.title : 'welcomeBackHeader';
		const translate = this.props.translate;
		const migratedConsoleUrl = this.props.console_settings ? this.props.console_settings.MIGRATED_CONSOLE_URL : '#';
		const migration_status = (this.props.console_settings && this.props.console_settings.MIGRATION_STATUS) ? this.props.console_settings.MIGRATION_STATUS : null;
		const notMigrated = (migration_status && migration_status.migration_complete) ? false : true;
		const onDev = (this.props.crn_parsed && this.props.crn_parsed.service_name === 'blockchain-dev') ? true : false;
		const ingressIsMigrated = (migration_status && migration_status.ingress_start_ts) ? true : false;

		let tag;
		if (this.state.pageState !== STATES.IDLE)
			tag = <TagSkeleton className="hyperion-console-version" />;
		else if (this.props.console_settings && this.props.console_settings.VERSIONS)
			tag = <Tag className="hyperion-console-version"
				type='gray'
			>
				{this.props.console_settings.VERSIONS.tag ? this.props.console_settings.VERSIONS.tag : this.props.console_settings.VERSIONS.athena}
			</Tag>;

		let tokens = '';
		if (this.props.accessToken) {
			tokens += '?accessToken=' + this.props.accessToken;
			tokens += '&refreshToken=' + this.props.refreshToken;
		}

		console.log('[migrate] console_settings', this.props.console_settings);
		console.log('[migrate] migration_status', migration_status);
		console.log('[migrate] resource', this.props.resource);
		console.log('[migrate] endpoint', endpoint);
		console.log('[migrate] notMigrated', notMigrated);
		console.log('[migrate] ingressIsMigrated', ingressIsMigrated);
		console.log('[migrate] migratedConsoleUrl', migratedConsoleUrl);
		console.log('[migrate] tokens', tokens ? '(not blank)' : '(blank)');

		return (
			<div>
				<h1 className="type-heading-05 mb-s-07 hyperion-versioned-header">
					{translate(title)}
					{tag}
				</h1>
				<div className="hyperion-page-content">
					<div className="d-flex">
						<div className="hyperion-inner-content">
							{(notMigrated &&
								<div>
									<p className="type-body-short-01 mb-s-06">{translate('welcomeBackMessage')}</p>

									<p className="type-body-short-01 mb-s-06">{translate('welcomeBackMessage2')}</p>

									<InstanceInfo className="mb-s-06"
										serviceID={instance_id}
										clusterName={clusterName}
									/>

									<p className="type-body-short-01 mb-s-07">{translate('welcome_back_try_refresh')}</p>
								</div>
							)}
							{(!notMigrated &&
								<div>
									<p className="type-body-short-01 mb-s-06">{translate('hasMigratedMessage')}</p>
									<p>
										<a href={migratedConsoleUrl} target='_blank'>{translate('migratedConsole')}</a>
									</p>
									<p>
										<a href={endpoint + tokens} target='_blank'>{translate('oldConsole')}</a>
									</p>
									<br />

									<InstanceInfo className="mb-s-06"
										serviceID={instance_id}
										clusterName={clusterName}
									/>
								</div>
							)}

							<InlineNotifications />

							{(!ingressIsMigrated && <Button
								className='mb-s-07 hyperion-refresh-button'
								kind='tertiary'
								renderIcon={this.state.pageState !== STATES.IDLE ? InlineLoading : Restart20}
								disabled={this.state.pageState !== STATES.IDLE}
								onClick={() => { this.refreshCluster(); }}
							>
								{translate('refresh_cluster')}
							</Button>
							)}

							<div className='mb-s-03'>
								<Link
									className='type-body-short-01'
									href={translate('_SUPPORT', { BMIX_HOST: this.props.bmixHost })}
									target="_blank"
									rel="noopener noreferrer"
								>
									{translate('contact_support')}
								</Link>
							</div>

							<Link
								className='type-body-short-01'
								href={translate('_DOCUMENTATION', { BMIX_HOST: this.props.bmixHost })}
								target="_blank"
								rel="noopener noreferrer"
							>
								{translate('documentation')}
							</Link>
						</div>

						<div className="ml-l-05 d-none d-lg-block">
							<img className="hyperion-banner-image" src='/returning_user_illustration.svg' alt="Welcome illustration" />
						</div>
					</div>

					{(notMigrated &&
						<Button
							className='hyperion-button-launch'
							renderIcon={Launch20}
							disabled={!endpoint || this.state.pageState !== STATES.IDLE || (!onDev && tokens === '')}
							onClick={() => {
								window.open(endpoint + tokens, '_blank');
							}}
						>
							{translate('launchIbp')}
						</Button>
					)}
				</div>
			</div>
		);
	}
}

const dataProps = {
	translate: PropTypes.func // Provided by withLocalize
};

WelcomeBack.propTypes = {
	...dataProps
};

export default connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['bmixHost'] = state['settings'] ? state['settings']['bmixHost'] : null;
		newprops['REFRESH_CONSOLE_UPTIME_THRESHOLD'] = state['settings'] ? state['settings']['REFRESH_CONSOLE_UPTIME_THRESHOLD'] : null;
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		newprops['optools_error'] = state['settings'] ? state['settings']['optools_error'] : null;
		newprops['resource'] = state['settings'] ? state['settings']['resource'] : null;
		newprops['console_settings'] = state['settings'] ? state['settings']['console_settings'] : null;
		newprops['accessToken'] = state['settings'] ? state['settings']['accessToken'] : null;
		newprops['refreshToken'] = state['settings'] ? state['settings']['refreshToken'] : null;

		return newprops;
	},
	{
		updateState,
		showError,
		showWarning,
		showSuccess,
		clearNotifications,
		toggleToastNotifications
	}
)(withLocalize(WelcomeBack));
