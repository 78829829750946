/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { Provider } from 'react-redux';
import './index.scss';
import App from './App';
import store from './redux/Store';

ReactDOM.render(
	<Provider store={store}>
		<LocalizeProvider>
			<App />
		</LocalizeProvider>
	</Provider>,
	document.getElementById('root'));