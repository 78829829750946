/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateState } from '../../redux/commonActions';
import Helper from '../../utils/helper';
import ButtonBar from '../ButtonBar/ButtonBar';
import TranslateLink from '../TranslateLink/TranslateLink';
import { Launch16 } from '@carbon/icons-react';
import { Accordion, AccordionItem, Button, CodeSnippet, Link, Modal } from 'carbon-components-react';
import Clipboard from '../../utils/clipboard';

const SCOPE = 'createPage';

// Links out to the IBM Kubernetes create cluster page
class Create extends Component {

	renderCreateContent (translate) {
		return (
			<AccordionItem
				title={translate('create_test')}
				open={true}
			>
				<p className="type-body-short-01 mb-s-06">{translate('test_desc')}</p>

				<p className="type-body-short-01 mb-s-03">{translate('create_test_need')}</p>
				<div className="code-snippet-link-container">
					<CodeSnippet
						className="mr-s-03"
						type="inline"
						onClick={() => { Clipboard.copyToClipboard(translate('create_test_specs')); }}
					>
						{translate('create_test_specs')}
					</CodeSnippet>
					<Link
						target='_blank'
						href={translate('_REGIONS', { BMIX_HOST: this.props.bmixHost })}
					>
						{translate('create_test_regions')}
					</Link>
				</div>
			</AccordionItem>
		);
	}

	renderJoinProdContent (translate) {
		return (
			<AccordionItem
				title={translate('join_prod')}
			>
				<p className="type-body-short-01 mb-s-06">{translate('join_desc')}</p>

				<p className="type-body-short-01 mb-s-03">{translate('create_test_need')}</p>
				<div className="code-snippet-link-container">
					<CodeSnippet
						className="mr-s-03"
						type="inline"
						onClick={() => { Clipboard.copyToClipboard(translate('create_join_specs')); }}
					>
						{translate('create_join_specs')}
					</CodeSnippet>
					<Link
						target='_blank'
						href={translate('_REGIONS', { BMIX_HOST: this.props.bmixHost })}
					>
						{translate('create_test_regions')}
					</Link>
				</div>
			</AccordionItem>
		);
	}

	renderCreateProdContent (translate) {
		return (
			<AccordionItem
				title={translate('create_prod')}
			>
				<p className="type-body-short-01 mb-s-06">{translate('create_desc')}</p>

				<p className="type-body-short-01 mb-s-03">{translate('create_test_need')}</p>
				<div className="code-snippet-link-container">
					<CodeSnippet
						className="mr-s-03"
						type="inline"
						onClick={() => { Clipboard.copyToClipboard(translate('create_join_specs')); }}
					>
						{translate('create_join_specs')}
					</CodeSnippet>
					<Link
						target='_blank'
						href={translate('_REGIONS', { BMIX_HOST: this.props.bmixHost })}
					>
						{translate('create_test_regions')}
					</Link>
				</div>
			</AccordionItem>
		);
	}

	renderAboutFree (translate) {
		return (
			<AccordionItem
				title={translate('about_iks')}
			>
				<p className="type-body-short-01 mb-s-06">{translate('about_desc')}</p>

				<p className="type-body-short-01 mb-s-03">{translate('create_free_need')}</p>

				<CodeSnippet
					type="inline"
					onClick={() => { Clipboard.copyToClipboard(translate('create_free_specs')); }}
				>
					{translate('create_free_specs')}
				</CodeSnippet>
			</AccordionItem>
		);
	}

	toggleModal (open) {
		return this.props.updateState(SCOPE, {
			openCreateModal: !!open
		});
	}

	render () {
		const translate = this.props.translate;
		return (
			<div className="hyperion-page-content">
				<h1 className="type-heading-05 mb-s-07">{translate('createIksHeader')}</h1>

				<TranslateLink
					className="type-body-short-01 mb-s-07"
					text="create_description"
					params={{ BMIX_HOST: this.props.bmixHost }}
				/>

				<Button
					className="mb-s-07"
					kind="tertiary"
					onClick={() => {
						this.props.updateState(SCOPE, {
							openCreateModal: true
						});
					}}
					renderIcon={Launch16}
				>
					{translate('create_link')}
				</Button>

				<Modal
					open={this.props.openCreateModal}
					focusTrap={true}
					modalHeading={translate('create_modal_heading')}
					modalAriaLabel={translate('create_modal_heading')}
					primaryButtonText={translate('create_modal_continue')}
					secondaryButtonText={translate('create_modal_cancel')}
					onRequestClose={() => { this.toggleModal(false); }}
					onRequestSubmit={() => {
						window.open(translate('_CREATE', { BMIX_HOST: this.props.bmixHost }), '_blank');
					}}
				>
					<p className="type-body-short-01 mb-s-05">{translate('create_modal_desc_1')}</p>
					<CodeSnippet
						className="mb-s-05"
						light={true}
						type="inline"
						onClick={() => { Clipboard.copyToClipboard(translate('create_test_specs')); }}
					>
						{translate('create_test_specs')}
					</CodeSnippet>
					<p className="type-body-short-01">{translate('create_modal_desc_2')}</p>
				</Modal>

				<h5 className="type-heading-01 mb-s-05">{translate('create_explore_requirements')}</h5>

				<Accordion>
					{this.renderCreateContent(translate)}
					{this.renderJoinProdContent(translate)}
					{this.renderCreateProdContent(translate)}
					{this.renderAboutFree(translate)}
				</Accordion>

				<ButtonBar>
					<Button
						kind="secondary"
						onClick={() => {
							const url = `/wizard?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
							this.props.history.push(url);
						}}
					>
						{translate('back')}
					</Button>
					<Button
						kind="primary"
						onClick={() => {
							const url = `/choose?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
							this.props.history.push(url);
						}}
					>
						{translate('continue')}
					</Button>
				</ButtonBar>
			</div>
		);
	}
}

const dataProps = {
	openCreateModal: PropTypes.bool,
	translate: PropTypes.func // Provided by withLocalize
};

Create.propTypes = {
	...dataProps
};

export default connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		newprops['bmixHost'] = state['settings'] ? state['settings']['bmixHost'] : null;
		return newprops;
	},
	{
		updateState
	}
)(withLocalize(Create));
