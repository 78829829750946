/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
const Helper = {
	mapStateToProps: (state, propTypes) => {
		const newState = {};
		const keys = Object.keys(propTypes);
		keys.forEach(key => {
			if (state && state[key] !== undefined)
				newState[key] = state[key];

		});
		return newState;
	},

	//------------------------------------------------------------
	// format timestamp in ms to x.x FRIENDLY_UNITS. ex: 6.4 mins, or 2.0 secs (negative values become 0)
	//------------------------------------------------------------
	friendly_ms: (ms) => {
		let ret = '';
		ms = Number(ms);
		if (isNaN(ms)) ret = '? sec';
		else if (ms <= 0) ret = '0 secs';
		else if (ms > 24 * 60 * 60 * 1000) ret = (ms / 1000 / 60 / 60 / 24).toFixed(1) + ' days'; //format for days
		else if (ms > 60 * 60 * 1000) ret = (ms / 1000 / 60 / 60).toFixed(1) + ' hrs'; 	//format for hours
		else if (ms > 60 * 1000) ret = (ms / 1000 / 60).toFixed(1) + ' mins'; 			//format for mins
		else if (ms > 1000) ret = (ms / 1000).toFixed(1) + ' secs'; 						//format for secs
		else ret = ms.toFixed(1) + ' ms'; 												//format to ms
		return ret;
	}
};

export default Helper;
