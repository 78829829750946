/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
const Clipboard = {

	/**
	 * Copies a string to the clipboard. Must be called from within an event handler such as click. May return false if
	 * it failed, but this is not always possible. Browser support for Chrome 43+, Firefox 42+, Safari 10+, Edge and IE
	 * 10+.  IE: The clipboard feature may be disabled by an administrator. By default a prompt is shown the first time
	 * the clipboard is used (per session).
	 * {@link https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript}
	 * @param {string} text Text to be copied to the clipboard.
	 * @return {boolean} True if the text was copied. False otherwise.
	 */
	copyToClipboard (text) {

		// IE specific code path to prevent textarea being shown while dialog is visible.
		if (window.clipboardData && window.clipboardData.setData)
			return window.clipboardData.setData('Text', text);

		else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
			const textarea = document.createElement('textarea');
			textarea.textContent = text;
			textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in MS Edge.
			document.body.appendChild(textarea);
			textarea.select();
			try {
				return document.execCommand('copy'); // Security exception may be thrown by some browsers.
			} catch (ex) {
				console.warn('Copy to clipboard failed.', ex);
				return false;
			} finally {
				document.body.removeChild(textarea);
			}
		}
	}
};

export default Clipboard;
