/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import Create from './components/Create/Create';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { connect } from 'react-redux';
import Helper from './utils/helper';
import LinkToCluster from './components/LinkToCluster/LinkToCluster';
import Success from './components/Success/Success';
import Timeline from './components/Timeline/Timeline';
import Welcome from './components/Welcome/Welcome';
import WelcomeBack from './components/WelcomeBack/WelcomeBack';
import { withLocalize } from 'react-localize-redux';
import { showError, updateState, toggleToastNotifications } from './redux/commonActions';
import localization from './utils/localization';
import './app.scss';
import SettingsAPI from './rest/SettingsAPI';
import { RESOURCE_API_ERRORS, ResourceAPI } from './rest/ResourceAPI';
import ClusterUnreachable from './components/ClusterUnreachable/ClusterUnreachable';
import Notifications from './components/Notifications/Notifications';
import SkeletonPage from './components/SkeletonPage/SkeletonPage';
import { REST_API_ERRORS } from './rest/RestApi';

const STATES = {
	INITIALIZING: 'INITIALIZING',
	ERROR: 'ERROR',
	READY: 'READY'
};

const SETTINGS_SCOPE = 'settings';
const CHECK_OPTOOLS_TIMEOUT = 30 * 1000; // 30 seconds

class App extends Component {

	constructor(props) {
		super(props);
		localization.init(props);
		this.state = {
			pageState: STATES.INITIALIZING,
			desired_path: null
		};
	}

	async componentDidMount() {

		const ace_config = queryString.parse(window.location.search).ace_config;
		if (!ace_config)
			return console.error('Not ace config object was passed in through query parameters.  App cannot continue.');

		const crnArray = JSON.parse(ace_config).id.split(':');
		const crn_parsed = {
			version: crnArray[1],
			c_name: crnArray[2],
			c_type: crnArray[3],
			service_name: crnArray[4],
			location: crnArray[5],
			account_id: crnArray[6].substring(2),
			instance_id: crnArray[7],
			resource_type: crnArray[8],
			resource_id: crnArray[9],
			ace_string: ace_config,
			crn_string: JSON.parse(ace_config).id
		};
		console.log(`Parsed CRN from query parameters: ${crn_parsed.crn_string}`);

		// TODO make this a config parameter that we get from the settings API
		// Select IBM Cloud url for staging vs prod
		let bmixHost = 'https://cloud.ibm.com';
		if (crn_parsed.c_name === 'staging' || crn_parsed.service_name === 'blockchain-dev')
			bmixHost = 'https://test.cloud.ibm.com';

		this.props.updateState(SETTINGS_SCOPE, {
			betaOver: false,
			test: null
		});

		console.log('Getting app settings...');
		let test, settings;
		try {
			settings = await SettingsAPI.getSettings();
			test = settings.test;
			console.log('Got app settings:', settings);
			this.props.updateState(SETTINGS_SCOPE, {
				isBeta: settings.isBeta,
				betaOver: settings.betaOver,
				test: settings.test,
				CONSECUTIVE_CONSOLE_RESPONSES: settings.CONSECUTIVE_CONSOLE_RESPONSES,
				REFRESH_CONSOLE_UPTIME_THRESHOLD: settings.REFRESH_CONSOLE_UPTIME_THRESHOLD,
				bmixHost,
				crn_parsed
			});
		} catch (error) {
			console.error('Could not get app settings', error);
			this.props.showError('error_status_not_determined_title', {}, SETTINGS_SCOPE, 'error_status_not_determined');
			return;
		}

		console.log(`Getting status of resource ${crn_parsed.crn_string}`);
		let resource, desired_path = '', pageState = '';
		try {
			resource = await ResourceAPI.getResource(crn_parsed.crn_string);
			if (resource && resource.resource) resource = resource.resource; // unwrap the response
			console.log(`Resource is linked to cluster ${resource.clusterName}:`, resource);
			this.props.updateState(SETTINGS_SCOPE, {
				resource
			});

		} catch (error) {
			console.error(`Error getting resource status for ${crn_parsed.crn_string}:`, error && error.code);

			// Any error handling page we land on may want to pull some info from the resource doc (ex. cluster id)
			if (error.response && error.response.resource) {
				console.log('Here\'s all the resource info we have:', error.response.resource);
				this.props.updateState(SETTINGS_SCOPE, {
					resource: error.response.resource
				});
			}

			// If we couldn't even reach Hyperion, there's not much we should do.  The user should refresh the page.
			if (!error.code || error.code !== REST_API_ERRORS.REQUEST_ERROR_IN_RESPONSE) {
				console.log('Status of the resource could not be determined');
				this.props.showError('error_status_not_determined_title', {}, SETTINGS_SCOPE, 'error_status_not_determined');
				pageState = STATES.ERROR;

			} else if (error.code === REST_API_ERRORS.REQUEST_ERROR_IN_RESPONSE) {

				pageState = STATES.READY;

				// No resource info means the user is trying to link an instance
				if (error.response && error.response.error === 'BACKEND_INSTANCE_DOES_NOT_EXIST') {
					console.log('This is a new resource.  Displaying welcome page');
					desired_path = '/wizard';
				} else {
					console.log('Something else is wrong with this resource:', error.response && error.response.error,
						error.response && error.response.message);
					// leave some error information for the error page to display
					this.props.updateState(SETTINGS_SCOPE, {
						resource_error: error.response
					});
					desired_path = '/bad-cluster';
				}
			}
			this.setState({
				pageState,
				desired_path
			});
			return;
		}

		console.log(`Checking whether console is reachable at ${resource.endpoints && resource.endpoints.api_endpoint}`);
		pageState = STATES.READY;
		desired_path = '/welcome-back';

		if (test && test.FORCE_UI_INIT_OPTOOLS_CHECK_ERROR) {
			console.warn('FORCING AN ERROR INDICATING OPTOOLS COULD NOT BE REACHED');
			this.props.updateState(SETTINGS_SCOPE, {
				optools_error: {
					error: 'FORCE_UI_INIT_OPTOOLS_CHECK_ERROR',
					message: 'This is a fake error for testing.  You shouldn\'t be seeing this.'
				}
			});

		} else {

			// get the console status by getting the console settings
			let console_settings = null;
			try {
				const response = await ResourceAPI.waitForOpTools(crn_parsed.crn_string, CHECK_OPTOOLS_TIMEOUT);
				console.log(`Got response from ${resource.endpoints && resource.endpoints.api_endpoint}`, response);
				console_settings = response ? response.settings : null;
			} catch (error) {
				if (error && error.code === RESOURCE_API_ERRORS.OPTOOLS_HARD_TO_REACH) {
					console_settings = error.last_successful_response && error.last_successful_response.settings;
				}
				console.error(`Could not reach OpTools: ${error}`);
				this.props.updateState(SETTINGS_SCOPE, {
					optools_error: error
				});
			}

			// get iam token for user
			try {
				console.log('[migrate] 1 trying iam token api');
				const iamResp = await ResourceAPI.getIamToken(crn_parsed.crn_string);
				console.log('[migrate] 1 got iam token response');
				this.props.updateState(SETTINGS_SCOPE, {
					accessToken: iamResp ? iamResp.accessToken : '',
					refreshToken: iamResp ? iamResp.refreshToken : '',
				});
			} catch (error) {
				console.error('[migrate] 1 unable to get iam token response', error);
			}

			// set console settings after the iam token exchange
			this.props.updateState(SETTINGS_SCOPE, {
				console_settings: console_settings
			});
			console.log('[migrate] console settings', console_settings);

			this.setState({
				pageState,				// this should happen last, once everything has loaded
				desired_path
			});
		}
	}

	render() {
		return (
			<div className="hyperion-app-container">
				{this.state.pageState !== STATES.READY && <SkeletonPage />}

				{this.state.pageState === STATES.READY && this.state.desired_path &&
					<Router>
						<div role="main" className="hyperion-page-container">
							<Timeline />
							<Route exact path="/welcome" render={() => (<Redirect to={{ pathname: '/', search: window.location.search }} />)} />
							<Route path="/wizard" component={Welcome} />
							<Route path="/choose" component={LinkToCluster} />
							<Route path="/create" component={Create} />
							<Route path="/success" component={Success} />
							<Route path="/welcome-back" component={WelcomeBack} />
							<Route path="/bad-cluster" component={ClusterUnreachable} />
							<Route exact path="/" render={() => (<Redirect to={{ pathname: this.state.desired_path, search: window.location.search }} />)} />
						</div>
					</Router>
				}

				<Notifications />
			</div>
		);
	}
}

const dataProps = {
	betaOver: PropTypes.bool,
	bmixHost: PropTypes.string,
	crn_parsed: PropTypes.object,
	isBeta: PropTypes.bool,
	optools_error: PropTypes.object,
	resource: PropTypes.object,
	resource_error: PropTypes.object,
	test: PropTypes.object,
	accessToken: PropTypes.string,
	refreshToken: PropTypes.string,
};

App.propTypes = {
	...dataProps
};
export default connect(
	state => {
		return Helper.mapStateToProps(state[SETTINGS_SCOPE], dataProps);
	},
	{
		updateState,
		showError,
		toggleToastNotifications
	}
)(withLocalize(App));
