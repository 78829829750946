/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToastNotifications } from '../../redux/commonActions';
import Helper from '../../utils/helper';
import WelcomeBack from '../WelcomeBack/WelcomeBack';

const SCOPE = 'success';

/**
 * Success page displays when cluster has been associated with the service instance.
 */
class Success extends Component {

	async componentDidMount () {
		this.props.toggleToastNotifications(false);
	}

	render () {
		return (
			<WelcomeBack
				title="successHeader"
			/>
		);
	}
}

const dataProps = {};

Success.propTypes = {
	...dataProps
};

export default connect(
	state => {
		return Helper.mapStateToProps(state[SCOPE], dataProps);
	},
	{
		toggleToastNotifications
	}
)(Success);
