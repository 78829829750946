/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { RestApi, REST_API_ERRORS } from './RestApi';
import Helper from '../utils/helper';

const DEFAULT_UPTIME_THRESHOLD = 2.5 * 60 * 1000; // 2.5 minutes
const DEFAULT_OPTOOLS_TIMEOUT = 5 * 60 * 1000; // 5 minutes
const DEFAULT_REFRESH_TIMEOUT = 6 * 60 * 1000; // Give refreshes an extra minute for containers to restart
const OPTOOLS_CHECK_INTERVAL = 3 * 1000; // 3 seconds
const DEFAULT_CONSECUTIVE_RESPONSES = 5;

class ResourceAPI {

	/**
	 * Associates the given cluster with the given service instance/resource.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @param {string} cluster_id The name or ID of an IBM Cloud Kubernetes Service cluster.
	 * @return {Promise<object>} A promise that resolves with the information for the given resource or rejects with an error.
	 */
	static async createResource(crn, cluster_id) {
		if (!crn || typeof crn !== 'string') {
			throw new TypeError('Invalid CRN for refreshCluster');

		} if (!cluster_id || typeof cluster_id !== 'string') {
			throw new TypeError('Invalid cluster for refreshCluster');
		}

		return await RestApi.post(`/api/resources/${encodeURIComponent(crn)}`, { cluster_id });
	}

	/**
	 * Get's the resource information for the current cloud resource, including cluster information.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @return {Promise<object>} A promise that resolves with the information for the given resource or rejects with an error.
	 */
	static async getResource(crn) {
		if (!crn || typeof crn !== 'string')
			throw new TypeError('Invalid CRN for refreshCluster');

		return await RestApi.get(`/api/resources/${encodeURIComponent(crn)}`);
	}

	/**
	 * Redeploys the OpTools deployment for a given resource.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @return {Promise<object>} A promise that resolves with the information for the given resource or rejects with an error.
	 */
	static async refreshResource(crn) {
		if (!crn || typeof crn !== 'string')
			throw new TypeError('Invalid CRN for refreshCluster');

		return await RestApi.put(`/api/resources/${encodeURIComponent(crn)}`);
	}

	/**
	 * Gets the list of clusters that can be linked with a given resource.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @return {Promise<object>} A promise that resolves with the list of clusters or rejects with an error.
	 */
	static async listResourceClusters(crn) {
		if (!crn || typeof crn !== 'string')
			throw new TypeError('Must provide a CRN to get the cluster list');

		return await RestApi.get(`/api/resources/${encodeURIComponent(crn)}/clusters`);
	}

	/**
	 * Checks the status of OpTools on the given resource.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @return {Promise<object>} A promise that resolves with the response from optools for the given resource,
	 * or rejects with an error.
	 */
	static async checkResourceOpToolsDeployment(crn) {
		return await RestApi.get(`/api/resources/${encodeURIComponent(crn)}/optools`);
	}

	/**
	 * Continues to ping Hyperion for the status of an OpTools deployment for a given service instance, giving up and
	 * rejecting with an error if the deployment takes too long to come up.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @param {number} [timeout] How long before we give up on OpTools.  Set with a logical default.
	 * @param {function} [shouldStop] A function that is called when a successful response is received.  If this
	 * function returns true, the requests to the console will stop and waitForOpTools will resolve.
	 * @param {function} [onError] A function that will be called whenever the request to the console fails.  Use this
	 * together with shouldStop to control whether this function should keep going or stop.
	 * @return {Promise<object>} A promise the resolves when OpTools is ready, or rejects with an error.
	 */
	static async waitForOpTools(crn, timeout = DEFAULT_OPTOOLS_TIMEOUT, shouldStop = () => { return true; }, onError = () => { }) {

		console.log(`Waiting ${crn}'s console to respond.  response timeout: ${Helper.friendly_ms(timeout)}`);
		const timeToGiveUp = new Date().getTime() + timeout;
		const stillTrying = true;
		while (stillTrying) {

			const currentTime = new Date().getTime();
			const timeLeft = timeToGiveUp - currentTime;
			if (timeLeft < 0) {
				const error = new Error('IBP Console took too long to come up');
				error.code = RESOURCE_API_ERRORS.OPTOOLS_TOOK_TOO_LONG;
				console.error(`${error}`);
				throw error;
			}

			console.log(`Checking to see if console is up yet.  ${Helper.friendly_ms(timeLeft)} left.`);
			try {
				const response = await ResourceAPI.checkResourceOpToolsDeployment(crn);
				console.log('Got console response:', response);

				if (shouldStop(response))
					return response;
			} catch (error) {
				console.error(`Error when checking status of console: ${error}`);
				if (error.code === REST_API_ERRORS.REQUEST_FAILED)
					console.log('Console request failed, but don\'t give up');
				else if (error.response && error.code !== REST_API_ERRORS.INVALID_RESPONSE_FORMAT) {
					const acceptable_codes = [
						'IBPCONSOLE_ERROR_IN_RESPONSE',
						'IBPCONSOLE_NO_RESPONSE',
						'IBPCONSOLE_REQUEST_FAILED'
					];
					if (acceptable_codes.indexOf(error.response.error) >= 0) {
						onError(error);
						console.log('Console is still coming up. Be patient.');
					} else
						throw error;
				} else
					throw error;

			}

			// Insert a delay between checks so we don't DDOS ourselves
			await new Promise((resolve => {
				setTimeout(() => {
					resolve();
				}, OPTOOLS_CHECK_INTERVAL);
			}));
		}
	}

	/**
	 * Extends waitForOptools to wait for some number of successful checks before signing off on the request to the
	 * console.  This allows us to feel more confident that there isn't still a load balancer in front of the console
	 * that isn't ready.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @param {number} [timeout] How long before we give up on OpTools.  Set with a logical default.
	 * @param {number} [successes] How many consecutive successful requests we need to see before the console is ready
	 * @return {Promise<object>} A promise the resolves when OpTools is ready, or rejects with an error.  If an response
	 * was received from the console at all, the last response will be attached to the error.
	 */
	static async waitForMultipleResponses(crn, timeout = DEFAULT_OPTOOLS_TIMEOUT, successes = DEFAULT_CONSECUTIVE_RESPONSES) {
		console.log(`Waiting ${crn}'s to respond ${successes} 5 consecutive times. response timeout: ${Helper.friendly_ms(timeout)}`);
		let consecutive = 0;
		let last_successful_response;
		try {
			return await this.waitForOpTools(crn, timeout, (response) => {
				consecutive++;
				console.log(`${consecutive}/${successes} consecutive responses received`);
				last_successful_response = response;
				return consecutive >= successes;
			}, (error) => {
				console.log('Console did not respond this time.  Resetting the count.');
				consecutive = 0;
			});
		} catch (error) {
			console.error(`Could not get ${successes} consecutive responses from console:`, error);
			if (error.code === RESOURCE_API_ERRORS.OPTOOLS_TOOK_TOO_LONG && last_successful_response) {
				error.code = RESOURCE_API_ERRORS.OPTOOLS_HARD_TO_REACH;
				error.last_successful_response = last_successful_response;
				console.log('We did get at least one response from the console, so let\'s use that:', error.last_successful_response);
			}
			throw error;
		}
	}

	/**
	 * Wraps the waitForOpTools logic with an additional check to determine if this instance of the console was recently
	 * started.  This is useful when refreshing a cluster, as it allows us to determine when the console instance has
	 * actually been restarted, not just when Deployer has finished sending updated k8s deployment specs to the clusters.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @param {number} [timeout] How long before we give up on OpTools.  Set with a logical default.
	 * @param {number} [uptime_threshold] If console uptime is below this number, we'll assume the console has been refreshed.
	 * @return {Promise<object>} A promise that resolves with the response from the restarted console or rejects with an error.
	 */
	static async waitForRefreshedOpTools(crn, timeout = DEFAULT_REFRESH_TIMEOUT, uptime_threshold = DEFAULT_UPTIME_THRESHOLD) {
		console.log(`Waiting ${crn}'s console to restart.  response timeout: ${Helper.friendly_ms(timeout)}.`,
			`max_uptime: ${Helper.friendly_ms(uptime_threshold)}`);
		try {
			return await ResourceAPI.waitForOpTools(crn, timeout, (response) => {
				// Console instances track their start and current time parameters in a TIMESTAMPS field in their settings
				// response
				// https://github.ibm.com/IBM-Blockchain/athena/blob/master/docs/other_apis.md#1-get-athena-settings
				const settings = response.settings;
				const now = settings && settings.TIMESTAMPS && settings.TIMESTAMPS.now;
				const born = settings && settings.TIMESTAMPS && settings.TIMESTAMPS.born;
				if (isNaN(now) || isNaN(born)) {
					const error = new Error('Uptime for the console could not be determined');
					error.code = RESOURCE_API_ERRORS.OPTOOLS_INVALID_UPTIME;
					error.response = response;
					throw error;
				}
				const uptime = now - born;
				console.log(`This console has been up for ${Helper.friendly_ms(uptime)}`);

				if (uptime < uptime_threshold) {
					console.log('We\'re getting a response from a new console.  Looks like the refresh has taken effect');
					return true;
				}
			});
		} catch (error) {
			console.error(`Error when waiting for console reset: ${error}`);
			throw error;
		}
	}

	/**
	 * Get's the resource information for the current cloud resource, including cluster information.
	 * @param {string} crn The cloud resource number for a Blockchain Platform service instance.
	 * @return {Promise<object>} A promise that resolves with the information for the given resource or rejects with an error.
	 */
	static async getIamToken(crn) {
		return await RestApi.get(`/api/iam/token/${encodeURIComponent(crn)}`);
	}
}

const RESOURCE_API_ERRORS = {
	OPTOOLS_TOOK_TOO_LONG: 'OPTOOLS_TOOK_TOO_LONG',
	OPTOOLS_HARD_TO_REACH: 'OPTOOLS_HARD_TO_REACH',
	OPTOOLS_MAY_NOT_BE_REFRESHING: 'OPTOOLS_MAY_NOT_BE_REFRESHING',
	OPTOOLS_INVALID_UPTIME: 'OPTOOLS_INVALID_UPTIME'
};

export {
	RESOURCE_API_ERRORS,
	ResourceAPI,
	DEFAULT_OPTOOLS_TIMEOUT,
	DEFAULT_UPTIME_THRESHOLD,
	DEFAULT_REFRESH_TIMEOUT,
	DEFAULT_CONSECUTIVE_RESPONSES
};
