/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Helper from '../../utils/helper';
import { withRouter } from 'react-router-dom';
import { updateState } from '../../redux/commonActions';
import { withLocalize } from 'react-localize-redux';
import { ProgressIndicator, ProgressStep } from 'carbon-components-react';
import PropTypes from 'prop-types';

const SCOPE = 'timeline';

// Various steps in the setup process with metadata to toggle their behavior.
const steps = [
	{
		name: 'welcomeAndPreReqs',
		pathname: '/wizard',
		clickable: true
	},
	{
		name: 'createCluster',
		subTitle: 'optional',
		pathname: '/create',
		clickable: true
	},
	{
		name: 'deployOntoCluster',
		pathname: '/choose',
		clickable: true
	},
	{
		name: 'launchConsole',
		pathname: '/success',
		trap: true
	}
];

/**
 * Renders a progress indicator on the left side of the page that tells the user where they are in the setup process.
 */
class Timeline extends Component {

	render () {

		const currentIndex = steps.findIndex((step) => {
			return step.pathname === this.props.location.pathname;
		});

		// Hide the timeline if it's not relevant to the current page
		if (currentIndex < 0) return null;

		const translate = this.props.translate;
		return (
			<ProgressIndicator
				className="hyperion-progress-indicator"
				vertical
				currentIndex={currentIndex}
				onChange={(next_step_index) => {
					const next_step = steps[next_step_index];
					// Prevent users from leaving the success page
					const isTrapped = steps[currentIndex].trap;

					if (next_step && next_step.pathname && next_step.clickable && !isTrapped) {
						console.log(`Changing page to ${next_step_index}: ${next_step.pathname}`);
						const url = `${next_step.pathname}?ace_config=${encodeURIComponent(this.props.crn_parsed.ace_string)}`;
						this.props.history.push(url);
					}
				}}
			>
				{steps.map((step, index) => {
					return (
						<ProgressStep
							key={index}
							label={<span>{translate(step.name)}</span>}
							secondaryLabel={step.subTitle ? translate(step.subTitle) : ''}
						/>
					);
				})}
			</ProgressIndicator>
		);
	}
}

const dataProps = {
	translate: PropTypes.func // Provided by withLocalize
};

Timeline.propTypes = {
	...dataProps
};

export default withRouter(connect(
	state => {
		const newprops = Helper.mapStateToProps(state[SCOPE], dataProps);
		newprops['crn_parsed'] = state['settings'] ? state['settings']['crn_parsed'] : null;
		return newprops;
	},
	{
		updateState
	}
)(withLocalize(Timeline)));
