/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { ToastNotification, InlineLoading } from 'carbon-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import { updateState } from '../../redux/commonActions';

const SCOPE = 'notifications';

const Notifications = ({ translate }) => {
	const dispatch = useDispatch();
	const list = useSelector(state => state[SCOPE] && state[SCOPE].list);
	const disable_toast_notifications = useSelector(state => state[SCOPE] && state[SCOPE].disable_toast_notifications);

	const clearNotification = (id) => {
		const filteredList = [];
		list.forEach(notify => {
			if (notify.id !== id)
				filteredList.push(notify);

		});
		dispatch(updateState(SCOPE, { list: filteredList }));
	};

	if (!list || !list.length || disable_toast_notifications)
		return null;

	return (
		<div className="ibp-notifications">
			{list.map(notify => {
				if (notify.autoClose && !notify.timer)
					notify.timer = setTimeout(() => {
						clearNotification(notify.id);
					}, 5000);

				// Allow notifications to have custom content beyond just text
				let subtitle = notify.details ? notify.details : '';
				if (typeof notify.details === 'string')
					subtitle = translate(notify.details, notify.options);

				return (
					<ToastNotification
						id={'notification_' + notify.id}
						kind={notify.type}
						title={translate(notify.message, notify.options)}
						subtitle={subtitle}
						iconDescription={translate('close')}
						onCloseButtonClick={() => clearNotification(notify.id)}
						caption={notify.type === 'info' ? '' : new Date(notify.id).toLocaleString()}
						style={{
							minWidth: '18rem',
							marginBottom: '0.5rem'
						}}
						key={notify.id}
						hideCloseButton={notify.type === 'info' || notify.options.disableCloseButton}
					>
						{notify.type === 'info' && notify.loading === true && <InlineLoading className="ibp-notification-icon-spin" />}
					</ToastNotification>
				);
			})}
		</div>
	);
};

Notifications.propTypes = {
	translate: PropTypes.func // Provided by withLocalize
};

export default withLocalize(Notifications);
