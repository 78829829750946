/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import PropTypes from 'prop-types';
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { Link } from 'carbon-components-react';

// Translates something stored in hte messages.json
// to be used with links in the message
const TranslateLink = props => {
	const translate = props.translate;
	let text = translate(props.text, props.params);
	const res = [];
	let s_idx = text.indexOf('[[');
	let e_idx = text.indexOf(']]');
	let key = 0;
	while (s_idx >= 0 && e_idx >= 0) {
		key++;
		const a_body = text.substring(s_idx + 2, e_idx);
		const s_p_idx = text.indexOf('(', e_idx);
		const e_p_idx = text.indexOf(')', s_p_idx);
		const link_name = text.substring(s_p_idx + 1, e_p_idx);
		const url = translate(link_name, props.params);
		res.push(text.slice(0, s_idx));
		text = text.slice(e_p_idx + 1);
		const a = (<Link key={key}
			href={url}
			target="_blank"
			rel="noopener noreferrer"
			disabled={!!props.disabled}
			inline={props.inline !== false}
			className={props.linkClassName ? props.linkClassName : props.className}>
			{a_body}
		</Link>);
		res.push(a);
		s_idx = text.indexOf('[[');
		e_idx = text.indexOf(']]');
	}
	res.push(text);
	return <div className={props.className}>{res}</div>;
};

TranslateLink.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	inline: PropTypes.bool,
	linkClassName: PropTypes.string,
	params: PropTypes.object,
	text: PropTypes.string,
	translate: PropTypes.func // Provided by withLocalize
};

export default withLocalize(TranslateLink);
