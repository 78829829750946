/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { RestApi } from './RestApi';

class SettingsAPI {

	/**
	 * Gets the settings for Hyperion, including any test flags.
	 * @return {Promise<object>} A Promise that resolves with the settings for Hyperion or rejects with an error.
	 */
	static async getSettings () {
		return await RestApi.get('/api/settings');
	}
}

export default SettingsAPI;