/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import { CLEAR_NOTIFICATIONS, TOGGLE_TOAST_NOTIFICATIONS, SHOW_NOTIFICATION, UPDATE_STATE } from './actions';

export const updateState = (scope, data) => {
	return {
		type: UPDATE_STATE,
		payload: {
			scope,
			data
		}
	};
};

export const showError = (message, options, scope, details, autoClose) => {
	return {
		type: SHOW_NOTIFICATION,
		payload: {
			type: 'error',
			message,
			details,
			options,
			scope,
			autoClose
		}
	};
};

export const showWarning = (message, options, scope, details, autoClose) => {
	return {
		type: SHOW_NOTIFICATION,
		payload: {
			type: 'warning',
			message,
			details,
			options,
			scope,
			autoClose
		}
	};
};

export const showSuccess = (message, options, scope, details, autoClose) => {
	return {
		type: SHOW_NOTIFICATION,
		payload: {
			type: 'success',
			message,
			details,
			options,
			scope,
			autoClose: autoClose === undefined ? true : autoClose
		}
	};
};

export const showInfo = (message, options, scope, details, autoClose, loading) => {
	return {
		type: SHOW_NOTIFICATION,
		payload: {
			type: 'info',
			message,
			details,
			options,
			scope,
			autoClose,
			loading
		}
	};
};

export const clearNotifications = scope => {
	return {
		type: CLEAR_NOTIFICATIONS,
		payload: scope
	};
};

export const toggleToastNotifications = enable => {
	return {
		type: TOGGLE_TOAST_NOTIFICATIONS,
		payload: {
			disable_toast_notifications: !enable
		}
	};
};
