/*
 * IBM Confidential
 * OCO Source Materials
 *
 * 5737-J29, 5737-B18
 *
 * (C) Copyright IBM Corp. 2018, 2019  All Rights Reserved.
 *
 * The source code for this program is not published or otherwise
 * divested of its trade secrets, irrespective of what has been
 * deposited with the U.S. Copyright Office.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { CodeSnippet } from 'carbon-components-react';
import Clipboard from '../../utils/clipboard';
import { withLocalize } from 'react-localize-redux';
import { WarningFilled16 } from '@carbon/icons-react';

/**
 * Info panels showing the service instance ID and the cluster info.
 * @param {string} [className] Additional classes to assign to the component.
 * @param {string} [clusterName] A cluster name.
 * @param {string} [serviceID] A service instance ID.
 * @param {function} translate Provided by withLocalize.
 * @returns {Component} An instance of the InstanceInfo component.
 */
function InstanceInfo ({ className, clusterName, serviceID, translate }) {
	return (
		<div className={`hyperion-instance-info-container ${className ? className : ''}`}>
			<InstanceInfoItem
				kind={!serviceID ? 'warn' : 'info'}
				title={translate('cloud_si_id')}
				text={serviceID ? serviceID : translate('unable_to_find_si_id')}
			/>
			<InstanceInfoItem
				kind={!clusterName ? 'warn' : 'info'}
				title={translate('associated_cluster')}
				text={clusterName ? clusterName : translate('unable_to_find_cluster')}
			/>
		</div>
	);
}

InstanceInfo.propTypes = {
	className: PropTypes.string,
	clusterName: PropTypes.string,
	serviceID: PropTypes.string,
	translate: PropTypes.func // Provided by withLocalize
};

/**
 * Describes a specific aspect of the instance, like instance ID, associated cluster, etc.
 * @param {string} [className] Additional classes to assign to the component.
 * @param {string} title The title of the info item.
 * @param {string} [text] The content of the info item.  If not provided, the info item will be styled with warning icons.
 * @param {string} [kind] Can be 'info' or 'warn'.  Defaults to 'info.  Controls the styling of the info item.
 * @constructor
 */
function InstanceInfoItem ({ className, kind, title, text }) {
	// Add support for danger or success styling here
	const kindClass = kind === 'warn' ? 'item-warn' : '';
	const fullClassName = `hyperion-instance-info-item ${kindClass} ${className ? className : ''}`;

	const safeText = text ? text : '';

	return (
		<div className={fullClassName}>
			<h4 className="type-label-01 mb-s-03">{title}</h4>
			<div className="d-flex">
				<CodeSnippet
					type="inline"
					className="hyperion-instance-info-item-text"
					onClick={() => { Clipboard.copyToClipboard(safeText); }}
				>{safeText}</CodeSnippet>
				{kind === 'warn' && <WarningFilled16 className={'hyperion-info-item-icon ml-s-03'}/>}
			</div>
		</div>
	);
}

InstanceInfoItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.string,
	kind: PropTypes.string
};

// Wrap the HOC in a variable to avoid syntax errors on the `export` keyword
const localizedInstanceInfo = withLocalize(InstanceInfo);
export {
	localizedInstanceInfo as InstanceInfo,
	InstanceInfoItem
};
